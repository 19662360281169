// Imports


// Plugins
const plugins = [{
  location: "/Users/grendel/Sites/nwvn-react",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins