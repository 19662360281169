import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

// top-level component
import App from './App';

// export top-level component as JSX (for static rendering)
export default App;

// render app
if (typeof document !== 'undefined') {
	const renderMethod = module.hot
		? ReactDOM.render
		: ReactDOM.hydrate || ReactDOM.render;
	const render = Comp => {
		renderMethod(
		<AppContainer>
			<Comp />
		</AppContainer>,
		document.getElementById('root')
		);
	}

	render(App);

	// set up hot module replacement
	if (module.hot) {
		module.hot.accept('./App', () => render(require('./App').default));
	}
}
