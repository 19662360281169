import React, { Component } from 'react';
import { Root, Head } from 'react-static';

import './app.css';
import scrape from '../public/images/scrape.png';
import target_icon from '../public/images/target.ico';
import target_png from '../public/images/target.png';

const ForumHead = () => (
	<Head>
		<title>nwvn</title>
		<link rel="shortcut icon" href={target_icon} />
		<link rel="icon" type="image/png" href={target_png} />

		<meta name="description" content="Continuous archival and retrieval of personal experience" />

		<meta property="og:title" content="nwvn" />
		<meta property="og:type" content="website" />
		<meta property="og:url" content="http://nwvn.net" />
		<meta property="og:description" content="Continuous archival and retrieval of personal experience" />
			
		<link href="https://fonts.googleapis.com/css?family=Inconsolata" rel="stylesheet" />
	</Head>
);

class App extends Component {
  render() {
    return (
		<Root autoScrollToTop>
			<ForumHead />
			<p><a href="#" target="_self"><img src={scrape} /></a></p>
			<p>restoration in progress ... / 24%</p>
		</Root>
    );
  }
}

export default App;
